<template>
  <div id="buy-from-jci-list">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="PreviewRecordPrompt" persistent width="auto">
      <buy-from-jci-preview
        pageDescription="Buy From JCI Preview"
        :PreviewRecordPrompt="PreviewRecordPrompt"
        :recordData="selectedData"
        @hideDialog="hidePreviewRecordPrompt"
        v-if="PreviewRecordPrompt"
      ></buy-from-jci-preview>
    </v-dialog>

    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>
      <v-card-text>
        <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
          <v-row wrap>
            <v-col cols="12" md="3">
              <label>
                <h6>
                  Zone
                  <small>(Optional)</small>
                </h6>
              </label>
              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="ZoneCodeOptionsLoading"
                :items="ZoneCodeOptions"
                :rules="ZoneCodeRules"
                v-model="ZoneCode"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                :disabled="!valid1"
                @click.prevent="searchForm"
                :loading="SearchFlag"
                color="info"
                class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
              >Search</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
      </v-card-text>
    </v-card>
    <br />
    <br />

    <v-card v-if="tableData.length > 0">
      <v-card-text>
        <v-container class="py-0">
          <v-row wrap>
            <v-col align="start" cols="12" md="12">
              <h4>Total No Of Transaction = {{ TotalNoOfTransaction }}</h4>
              <h4>Total Value Of Transaction = {{ TotalValueOfTransaction }}</h4>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <br />
    <br />

    <v-row wrap>
      <v-col align="center" cols="6" md="6">
        <v-card>
          <v-card-title class="text-h5">
            <h2>Top {{ tableData5.length == 0 ? '' : tableData5.length }} Buyer LO</h2>
          </v-card-title>
          <v-card-text>
            <v-container class="py-0">
              <v-row wrap>
                <v-col align="center" cols="12" md="12" v-if="tableData5.length <= 0">
                  <h6>No Records found.</h6>
                </v-col>
                <v-col align="center" cols="12" md="12" v-if="tableData5.length > 0">
                  <h6>{{ tableData5.length }} Records found.</h6>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="py-0" v-if="tableData5.length > 0">
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-data-table
                    class="elevation-1"
                    v-model="selected1"
                    :item-key="tableOptions5.ItemKey"
                    :single-select="tableOptions5.SingleSelectFlag"
                    :headers="tableColumns5"
                    :items="tableData5"
                    :items-per-page="tableOptions5.ItemsPerPage"
                    :footer-props="tableOptions5.FooterProps"
                    :hide-default-footer="tableOptions5.HideDefaultFooter"
                  ></v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col align="center" cols="6" md="6">
        <v-card>
          <v-card-title class="text-h5">
            <h2>Top {{ tableData6.length == 0 ? '' : tableData6.length }} Seller LO</h2>
          </v-card-title>
          <v-card-text>
            <v-container class="py-0">
              <v-row wrap>
                <v-col align="center" cols="12" md="12" v-if="tableData6.length <= 0">
                  <h6>No Records found.</h6>
                </v-col>
                <v-col align="center" cols="12" md="12" v-if="tableData6.length > 0">
                  <h6>{{ tableData6.length }} Records found.</h6>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="py-0" v-if="tableData6.length > 0">
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-data-table
                    class="elevation-1"
                    v-model="selected1"
                    :item-key="tableOptions6.ItemKey"
                    :single-select="tableOptions6.SingleSelectFlag"
                    :headers="tableColumns6"
                    :items="tableData6"
                    :items-per-page="tableOptions6.ItemsPerPage"
                    :footer-props="tableOptions6.FooterProps"
                    :hide-default-footer="tableOptions6.HideDefaultFooter"
                  ></v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <br />

    <v-card>
      <v-card-title class="text-h5">
        <h2>Top {{ tableData2.length == 0 ? '' : tableData2.length }} Buyers</h2>
      </v-card-title>
      <v-card-text>
        <v-container class="py-0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12" v-if="tableData2.length <= 0">
              <h6>No Records found.</h6>
            </v-col>
            <v-col align="center" cols="12" md="12" v-if="tableData2.length > 0">
              <h6>{{ tableData2.length }} Records found.</h6>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData2.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="tableOptions1.DownloadFlag"
                color="red"
                @click.prevent="generateExcel(tableData1,tableOptions1.ExcelFields, tableOptions1.ExcelFileName, 'My Worksheet')"
                class="font-size-h6 fsize-3 mr-3 white--text"
              >Download</v-btn>
            </v-col>
            <p></p>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-data-table
                class="elevation-1"
                v-model="selected1"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData2"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
                :hide-default-footer="tableOptions1.HideDefaultFooter"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <br />
    <br />

    <v-card>
      <v-card-title class="text-h5">
        <h2>Top {{ tableData4.length == 0 ? '' : tableData4.length }} Sellers</h2>
      </v-card-title>
      <v-card-text>
        <v-container class="py-0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12" v-if="tableData4.length <= 0">
              <h6>No Records found.</h6>
            </v-col>
            <v-col align="center" cols="12" md="12" v-if="tableData4.length > 0">
              <h6>{{ tableData4.length }} Records found.</h6>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData4.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="tableOptions3.DownloadFlag"
                color="red"
                @click.prevent="generateExcel(tableData3,tableOptions3.ExcelFields, tableOptions3.ExcelFileName, 'My Worksheet')"
                class="font-size-h6 fsize-3 mr-3 white--text"
              >Download</v-btn>
            </v-col>
            <p></p>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-data-table
                class="elevation-1"
                v-model="selected1"
                :item-key="tableOptions3.ItemKey"
                :single-select="tableOptions3.SingleSelectFlag"
                :headers="tableColumns3"
                :items="tableData4"
                :items-per-page="tableOptions3.ItemsPerPage"
                :footer-props="tableOptions3.FooterProps"
                :hide-default-footer="tableOptions3.HideDefaultFooter"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <br />
    <br />

    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>
      <v-card-text>
        <v-container class="py-0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12" v-if="tableData.length <= 0">
              <h6>No Records found.</h6>
            </v-col>
            <v-col align="center" cols="12" md="12" v-if="tableData.length > 0">
              <h6>{{ tableData.length }} Records found.</h6>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="tableOptions.DownloadFlag"
                color="red"
                @click.prevent="generateExcel(tableData,tableOptions.ExcelFields, tableOptions.ExcelFileName, 'My Worksheet')"
                class="font-size-h6 fsize-3 mr-3 white--text"
              >Download</v-btn>
            </v-col>
            <p></p>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="tableOptions.ItemKey"
                :single-select="tableOptions.SingleSelectFlag"
                :headers="tableColumns"
                :items="tableData"
                :items-per-page="tableOptions.ItemsPerPage"
                :footer-props="tableOptions.FooterProps"
              >
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.PreviewFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-file-search</v-icon>
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import BuyFromJciPreview from "@/view/pages/erp/buy-from-jci/BuyFromJCIPreview";

export default {
  mixins: [common],
  components: {
    BuyFromJciPreview
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      ProgressLoadingFlag: false,
      SearchFlag: false,

      progessStart: 0,

      PreviewRecordPrompt: false,

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      tableData: [],
      tableColumns: [],
      tableOptions: [],
      selected: [],
      search: "",

      tableData1: [],
      tableData2: [],
      tableColumns1: [],
      tableOptions1: [],
      selected1: [],
      search1: "",

      tableData3: [],
      tableData4: [],
      tableColumns3: [],
      tableOptions3: [],
      selected3: [],

      tableData5: [],
      tableColumns5: [],
      tableOptions5: [],
      selected5: [],

      tableData6: [],
      tableColumns6: [],
      tableOptions6: [],
      selected6: [],

      TotalValueOfTransaction: 0,
      TotalNoOfTransaction: 0,

      ZoneCode: "",
      LomCode: "",
      MemberId: "",
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  computed: {},
  mounted() {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.LoadingFlag = false;
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var MemberId = this.$session.get("MemberId");
        this.MemberId = MemberId;
        console.log({ MemberId });

        this.getZoneCodeOptions();
      }
    },
    ZoneCodeOptions: function() {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    }
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "buy_from_jci",
        Action: "report_1"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    addData() {
      console.log("addData called");
      this.$router.push("/buy-from-jci/create");
    },
    resetForm() {
      this.$refs.form1.reset();
      this.Buyer = {};
      this.BuyerFlag = false;
      this.Profession = {};
      this.ProfessionFlag = false;
    },
    backPage() {
      this.$router.go(-1);
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.ZoneCode = "";

      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm is called");
      this.getBuyerMembers();
      this.getSellerMembers();
      this.getBuyerLoms();
      this.getSellerLoms();
    },
    getBuyerMembers() {
      console.log("getBuyerMembers is called");

      var ZoneCode = this.ZoneCode;
      console.log("ZoneCode=" + ZoneCode);

      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.tableData = [];
      this.tableData1 = [];
      this.tableData2 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/buy-from-jci/buyers/report";
      var upload = {
        UserInterface: 1,
        Zone: ZoneCode
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          var records1 = response.data.records1;
          console.log("output=" + output + ", flag=" + flag);

          var options = records.TableOptions;
          console.log({ options });
          thisIns.tableOptions = options;

          var options1 = records1.TableOptions;
          console.log({ options1 });
          thisIns.tableOptions1 = options1;
          var Level = options1.Level;
          console.log("Level=" + Level);

          var TotalNoOfTransaction = response.data.TotalNoOfTransaction;
          var TotalValueOfTransaction = response.data.TotalValueOfTransaction;
          console.log(
            "TotalNoOfTransaction=" +
              TotalNoOfTransaction +
              ", TotalValueOfTransaction=" +
              TotalValueOfTransaction
          );

          if (flag == 1) {
            thisIns.tableColumns = records.TableHeader;
            thisIns.tableData = records.TableData;

            thisIns.tableColumns1 = records1.TableHeader;
            thisIns.tableData1 = records1.TableData;
            thisIns.tableData2 = records1.TableData.slice(0, Level);
            thisIns.TotalNoOfTransaction = TotalNoOfTransaction;
            thisIns.TotalValueOfTransaction = TotalValueOfTransaction;
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function(error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;
          thisIns.toast("error", error);
        });
    },
    getSellerMembers() {
      console.log("getSellerMembers is called");

      var ZoneCode = this.ZoneCode;
      console.log("ZoneCode=" + ZoneCode);

      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.tableData3 = [];
      this.tableData4 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/buy-from-jci/sellers/report";
      var upload = {
        UserInterface: 1,
        Zone: ZoneCode
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records1;
          console.log("output=" + output + ", flag=" + flag);

          var options = records.TableOptions;
          console.log({ options });
          thisIns.tableOptions3 = options;

          var Level = options.Level;
          console.log("Level=" + Level);

          if (flag == 1) {
            thisIns.tableColumns3 = records.TableHeader;
            thisIns.tableData3 = records.TableData;
            thisIns.tableData4 = records.TableData.slice(0, Level);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function(error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;
          thisIns.toast("error", error);
        });
    },
    getBuyerLoms() {
      console.log("getBuyerLoms is called");

      var ZoneCode = this.ZoneCode;
      console.log("ZoneCode=" + ZoneCode);

      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.tableData5 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/buy-from-jci/buyers/report";
      var upload = {
        UserInterface: 2,
        Zone: ZoneCode
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records1;
          console.log("output=" + output + ", flag=" + flag);

          var options = records.TableOptions;
          console.log({ options });
          thisIns.tableOptions4 = options;
          var Level = options.Level;
          console.log("Level=" + Level);

          if (flag == 1) {
            thisIns.tableColumns5 = records.TableHeader;
            thisIns.tableData5 = records.TableData.slice(0, Level);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function(error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;
          thisIns.toast("error", error);
        });
    },
    getSellerLoms() {
      console.log("getSellerLoms is called");

      var ZoneCode = this.ZoneCode;
      console.log("ZoneCode=" + ZoneCode);

      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.tableData6 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/buy-from-jci/sellers/report";
      var upload = {
        UserInterface: 2,
        Zone: ZoneCode
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records1;
          console.log("output=" + output + ", flag=" + flag);

          var options = records.TableOptions;
          console.log({ options });
          thisIns.tableOptions6 = options;
          var Level = options.Level;
          console.log("Level=" + Level);

          if (flag == 1) {
            thisIns.tableColumns6 = records.TableHeader;
            thisIns.tableData6 = records.TableData.slice(0, Level);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function(error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;
          thisIns.toast("error", error);
        });
    },
    previewData(tr) {
      console.log("previewData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.PreviewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one data to edit";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.PreviewRecordPrompt = false;
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#buy-from-jci-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>